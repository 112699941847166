.link-warning-wrapper {
    padding: 10px 0px;
}

.huge-link-icon {
    align-self: center;
    text-align: center;
    padding: 5px 50px;
    margin: 0;
    color: rgb(243, 213, 94);
}

div.account-img {
    text-align: center;
}

img.account-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px auto;
    border: 2px solid #ccc;
}