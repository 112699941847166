#btn-plantyn-login {
    background-color: rgb(104, 9, 148);
    color: white;
    border: 1px solid rgb(104, 9, 148);
    ;
}

#btn-plantyn-login:hover {
    background-color: rgb(156, 43, 209);
}

.idProvider {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
}